import styled from "styled-components";

const CoordinatesList = styled.dl`
  dt {
    clear: left;
    float: left;
    font-size: 12px;
    width: 80px;
  }

  dd {
    clear: right;
    font-size: 12px;
    line-height: 16px;
  }
`;

export function Coordinates() {
  return (
    <>
      <p>
        685, route des îles, St-Hilaire-de-Dorset
        <br />
        QC, G0M 1G0
      </p>
      <CoordinatesList>
        <dt>Téléphone :</dt>
        <dd>418-459-3521</dd>

        <dt>Sans-frais :</dt>
        <dd>1-855-677-3521</dd>
      </CoordinatesList>
      <CoordinatesList>
        <dt>Courriel :</dt>
        <dd>
          <a href="mailto:veroniquebellavance@campingdesiles2008.com">
            veroniquebellavance@campingdesiles2008.com
          </a>
        </dd>
      </CoordinatesList>
    </>
  );
}
