export interface Picture {
  href: string;
  thumbnail: string;
}

export const PICTURES: Picture[] = [
  {
    href: require("./images/camping 2013 vue aérienne.jpg"),
    thumbnail: require("./images/camping 2013 vue aérienne_low.jpg"),
  },
  {
    href: require("./images/503.jpg"),
    thumbnail: require("./images/503_low.jpg"),
  },
  {
    href: require("./images/20210707_045120685.jpg"),
    thumbnail: require("./images/20210707_045120685_low.jpg"),
  },
  {
    href: require("./images/20180825_140627.jpg"),
    thumbnail: require("./images/20180825_140627_low.jpg"),
  },
  {
    href: require("./images/20200603_183446.jpg"),
    thumbnail: require("./images/20200603_183446_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-06-04 20 51 31.jpg"),
    thumbnail: require("./images/Photo 2020-06-04 20 51 31_low.jpg"),
  },
  {
    href: require("./images/terrain 11.jpg"),
    thumbnail: require("./images/terrain 11_low.jpg"),
  },
  {
    href: require("./images/terrain7.jpg"),
    thumbnail: require("./images/terrain7_low.jpg"),
  },
  {
    href: require("./images/Photo 2019-11-01 12 19 49.jpg"),
    thumbnail: require("./images/Photo 2019-11-01 12 19 49_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-06-02 20 58 28.jpg"),
    thumbnail: require("./images/Photo 2020-06-02 20 58 28_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-06-02 20 59 00.jpg"),
    thumbnail: require("./images/Photo 2020-06-02 20 59 00_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-07-16 12 09 50.jpg"),
    thumbnail: require("./images/Photo 2020-07-16 12 09 50_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-08-11 16 55 21.jpg"),
    thumbnail: require("./images/Photo 2020-08-11 16 55 21_low.jpg"),
  },
  {
    href: require("./images/feu de camp 1.png"),
    thumbnail: require("./images/feu de camp 1_low.jpg"),
  },
  {
    href: require("./images/feux dartifices.png"),
    thumbnail: require("./images/feux dartifices_low.jpg"),
  },
  {
    href: require("./images/20200523_155158.jpg"),
    thumbnail: require("./images/20200523_155158_low.jpg"),
  },
  {
    href: require("./images/Photo 2020-08-22 10 06 25.jpg"),
    thumbnail: require("./images/Photo 2020-08-22 10 06 25_low.jpg"),
  },
  {
    href: require("./images/20200524_182702.jpg"),
    thumbnail: require("./images/20200524_182702_low.jpg"),
  },
  {
    href: require("./images/20160725_104906.jpg"),
    thumbnail: require("./images/20160725_104906_low.jpg"),
  },
  {
    href: require("./images/Photo 2017-08-19 13 34 59.jpg"),
    thumbnail: require("./images/Photo 2017-08-19 13 34 59_low.jpg"),
  },
  {
    href: require("./images/truite moucheté du lac des Iles.jpg"),
    thumbnail: require("./images/truite moucheté du lac des Iles_low.jpg"),
  },
  {
    href: require("./images/20210828_204813.jpg"),
    thumbnail: require("./images/20210828_204813_low.jpg"),
  },
  {
    href: require("./images/20210724_141115.jpg"),
    thumbnail: require("./images/20210724_141115_low.jpg"),
  },
  {
    href: require("./images/20210605_193915.jpg"),
    thumbnail: require("./images/20210605_193915_low.jpg"),
  },
  {
    href: require("./images/20220520_100924.jpg"),
    thumbnail: require("./images/20220520_100924_low.jpg"),
  },
  {
    href: require("./images/20220520_143117.jpg"),
    thumbnail: require("./images/20220520_143117_low.jpg"),
  },
  {
    href: require("./images/20220520_101126.jpg"),
    thumbnail: require("./images/20220520_101126_low.jpg"),
  },
  {
    href: require("./images/20220520_101035.jpg"),
    thumbnail: require("./images/20220520_101035_low.jpg"),
  },
];
