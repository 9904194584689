import { PropsWithChildren } from "react";
import styled from "styled-components";
import { LotPeople, LOTS, LotState } from "./people";

function lotStateToFillColor(state: LotState) {
  switch (state) {
    case LotState.Owner:
      return "#000000";
    case LotState.PassengerFree:
      return "#36A9E1";
    case LotState.PassengerTent:
      return "#36A9E1";
    case LotState.SeasonFree:
      return "#FFFFFF";
    case LotState.SeasonRented:
      return "#000000";
  }
}

function lotStateToTextColor(state: LotState) {
  switch (state) {
    case LotState.Owner:
      return "#FFFFFF";
    case LotState.PassengerFree:
      return "#000000";
    case LotState.PassengerTent:
      return "#000000";
    case LotState.SeasonFree:
      return "#000000";
    case LotState.SeasonRented:
      return "#FFFFFF";
  }
}

interface StyledGProps {
  state: LotState;
  interactive: boolean;
}

const StyledG = styled.g`
  rect,
  polyline,
  polygon,
  path {
    stroke: #cbbba0;
    fill: ${({ state }: StyledGProps) => lotStateToFillColor(state)};
  }

  text {
    fill: ${({ state }: StyledGProps) => lotStateToTextColor(state)};
  }

  ${({ interactive }: StyledGProps) =>
    interactive
      ? `
  &:hover {
    cursor: pointer;

    rect,
    polyline,
    polygon,
    path {
      fill: gray;
    }
  }
  `
      : ""}
`;

interface GProps extends LotsProps {
  id: string;
}

function G({ children, onClick, ...props }: PropsWithChildren<GProps>) {
  return (
    <StyledG
      {...props}
      interactive={Boolean(onClick)}
      onClick={() => {
        if (onClick) {
          onClick(LOTS[props.id]);
        }
      }}
      state={LOTS[props.id].state}
    >
      {children}
    </StyledG>
  );
}

interface LotsProps {
  onClick?: (lot: LotPeople) => void;
}

export const Lots = ({ onClick }: LotsProps) => {
  return (
    <svg
      version="1.1"
      id="Lots"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 792 612"
      enableBackground="new 0 0 792 612"
    >
      <G onClick={onClick} id="lot32">
        <rect
          x="8.333"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="86.666"
          height="47.995"
        />
        <text
          transform="matrix(1 0 0 1 44.6646 85.334)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          32
        </text>
      </G>
      <G onClick={onClick} id="lot5">
        <rect
          x="95"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="54.667"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 118.8594 99.3335)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          5
        </text>
      </G>
      <G onClick={onClick} id="lot6">
        <rect
          x="149.667"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="53.333"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 172.6138 99.6689)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          6
        </text>
      </G>
      <G onClick={onClick} id="lot7">
        <rect
          x="203"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="53.833"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 226.5205 99.333)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          7
        </text>
      </G>
      <G onClick={onClick} id="lot8">
        <rect
          x="256.833"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="54.667"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 280.1841 99.6665)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          8
        </text>
      </G>
      <G onClick={onClick} id="lot9">
        <rect
          x="311.5"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="53.5"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 333.7031 99.6689)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          9
        </text>
      </G>
      <G onClick={onClick} id="lot10">
        <rect
          x="365"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.667"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 383.9189 100.002)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          10
        </text>
      </G>
      <G onClick={onClick} id="lot41">
        <rect
          x="432.5"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="48.454"
          height="75.994"
        />
        <text
          transform="matrix(0.9378 0 0 1 450.8965 100.3335)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          41
        </text>
      </G>
      <G onClick={onClick} id="lot42">
        <rect
          x="481.167"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="48.833"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 497.3667 100.3335)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          42
        </text>
      </G>
      <G onClick={onClick} id="lot43">
        <rect
          x="530"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="46.5"
          height="75.994"
        />
        <text
          transform="matrix(1 0 0 1 545.4326 100.3335)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          43
        </text>
      </G>
      <G onClick={onClick} id="lot24">
        <rect
          x="546.557"
          y="254.718"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.75"
        />
        <text
          transform="matrix(1 0 0 1 576.0337 279.5405)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          24
        </text>
      </G>
      <G onClick={onClick} id="lot23">
        <rect
          x="546.557"
          y="297.469"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.754"
        />
        <text
          transform="matrix(1 0 0 1 575.9976 322.0381)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          23
        </text>
      </G>
      <G onClick={onClick} id="lot25">
        <rect
          x="640.557"
          y="254.831"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.751"
        />
        <text
          transform="matrix(1 0 0 1 668.9614 279.4131)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          25
        </text>
      </G>
      <G onClick={onClick} id="lot26">
        <rect
          x="640.557"
          y="297.582"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.754"
        />
        <text
          transform="matrix(1 0 0 1 668.7456 321.96)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          26
        </text>
      </G>
      <G onClick={onClick} id="lot22">
        <rect
          x="546.557"
          y="339.826"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.752"
        />
        <text
          transform="matrix(1 0 0 1 575.9316 364.0029)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          22
        </text>
      </G>
      <G onClick={onClick} id="lot27">
        <rect
          x="640.557"
          y="339.94"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.752"
        />
        <text
          transform="matrix(1 0 0 1 669.0698 364.3174)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          27
        </text>
      </G>
      <G onClick={onClick} id="lot21">
        <rect
          x="546.557"
          y="382.738"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.754"
        />
        <text
          transform="matrix(1 0 0 1 576.8438 406.7744)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          21
        </text>
      </G>
      <G onClick={onClick} id="lot28">
        <rect
          x="640.557"
          y="382.852"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="42.754"
        />
        <text
          transform="matrix(1 0 0 1 668.6187 406.7734)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          28
        </text>
      </G>
      <G onClick={onClick} id="lot40">
        <rect
          x="732.999"
          y="254.718"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="67.32"
        />
        <text
          transform="matrix(1 0 0 1 750.7734 294.0459)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          40
        </text>
      </G>
      <G onClick={onClick} id="lot39">
        <rect
          x="732.999"
          y="322.038"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="73.048"
        />
        <text
          transform="matrix(1 0 0 1 750.8169 363.5645)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          39
        </text>
      </G>
      <G onClick={onClick} id="lot38">
        <rect
          x="732.999"
          y="395.086"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="75.912"
        />
        <text
          transform="matrix(1 0 0 1 751.0498 438.334)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          38
        </text>
      </G>
      <G onClick={onClick} id="lot37">
        <rect
          x="732.999"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 751.1348 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          37
        </text>
      </G>
      <G onClick={onClick} id="lot35">
        <rect
          x="631.663"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 649.0547 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          35
        </text>
      </G>
      <G onClick={onClick} id="lot34">
        <rect
          x="580.995"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 599.4346 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          34
        </text>
      </G>
      <G onClick={onClick} id="lot33">
        <rect
          x="530.327"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 549.3135 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          33
        </text>
      </G>
      <G onClick={onClick} id="lot19">
        <rect
          x="479.659"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 499.3257 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          19
        </text>
      </G>
      <G onClick={onClick} id="lot18">
        <rect
          x="428.991"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 448.2227 512.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          18
        </text>
      </G>
      <G onClick={onClick} id="lot29">
        <rect
          x="378.323"
          y="470.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="72.887"
        />
        <text
          transform="matrix(1 0 0 1 396.4087 520.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          29
        </text>
      </G>
      <G onClick={onClick} id="lot30">
        <rect
          x="378.323"
          y="430.998"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="50.668"
          height="55.887"
        />
        <text
          transform="matrix(1 0 0 1 396.4087 462.4424)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          30
        </text>
      </G>
      <G onClick={onClick} id="lot17">
        <rect
          x="428.855"
          y="431"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="66.992"
          height="39.998"
        />
        <text
          transform="matrix(1 0 0 1 455.7544 454.666)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          17
        </text>
      </G>
      <G onClick={onClick} id="lot16">
        <rect
          x="428.855"
          y="393.125"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="90.143"
          height="37.875"
        />
        <text
          transform="matrix(1 0 0 1 467.7544 416.7314)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          16
        </text>
      </G>
      <G onClick={onClick} id="lot15">
        <rect
          x="428.855"
          y="352.987"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="90.143"
          height="40.138"
        />
        <text
          transform="matrix(1 0 0 1 467.9995 377.1689)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          15
        </text>
      </G>
      <G onClick={onClick} id="lot14">
        <polygon
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          points="429.357,353.15 518.998,353.15 518.998,288.806 	"
        />
        <text
          transform="matrix(1 0 0 1 489.1157 338.2178)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          14
        </text>
      </G>
      <G onClick={onClick} id="lot13">
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          d="M481.145,315.872l37.853-27.066l0.477-32.879
   c0,0-18.804,2.514-36.172,10.279c-20.934,9.359-36.443,20.941-36.443,20.941L481.145,315.872z"
        />
        <text
          transform="matrix(1 0 0 1 486.5796 288.8062)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          13
        </text>
      </G>
      <G onClick={onClick} id="lot12">
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          d="M480.954,315.547l-51.597,37.603l-31.402-22.369
   c0,0-1.955,1.06,19.722-21.188c11.426-11.728,28.991-22.772,28.991-22.772L480.954,315.547z"
        />
        <text
          transform="matrix(1 0 0 1 429.8467 325.6689)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          12
        </text>
      </G>
      <G onClick={onClick} id="lot44">
        <path
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          d="M428.991,393.125l-48.568-17.65c0,0-0.47,2.517,1.327-9.665
   c3.125-21.185,16.205-35.028,16.205-35.028l30.903,22.369L428.991,393.125z"
        />
        <text
          transform="matrix(1.0479 0 0 1 401.9443 366.4746)"
          fontFamily="'Montserrat'"
          fontSize="11.4516px"
        >
          44
        </text>
      </G>
      <G onClick={onClick} id="lot11">
        <polygon
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          points="428.991,431 378.323,431 380.423,375.475
   428.991,393.125 	"
        />
        <text
          transform="matrix(0.9674 0 0 1 400.084 412.2959)"
          fontFamily="'Montserrat'"
          fontSize="12.4045px"
        >
          11
        </text>
      </G>
      <G onClick={onClick} id="lot4">
        <rect
          x="81.221"
          y="231.662"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="50.668"
        />
        <text
          transform="matrix(1 0 0 1 114.2319 261.0024)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          4
        </text>
      </G>
      <G onClick={onClick} id="lot3">
        <rect
          x="81.221"
          y="282.329"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="50.668"
        />
        <text
          transform="matrix(1 0 0 1 114.1963 312.6641)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          3
        </text>
      </G>
      <G onClick={onClick} id="lot2">
        <rect
          x="81.221"
          y="332.652"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="50.668"
        />
        <text
          transform="matrix(1 0 0 1 115.1313 362.6543)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          2
        </text>
      </G>
      <G onClick={onClick} id="lot1">
        <rect
          x="8.333"
          y="332.652"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="72.887"
          height="50.668"
        />
        <text
          transform="matrix(1 0 0 1 42.4907 362.6543)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          1
        </text>
      </G>
      <G onClick={onClick} id="lot45-50">
        <rect
          x="576.5"
          y="58.336"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="147.417"
          height="87.994"
        />
        <text
          transform="matrix(1 0 0 1 627.9351 105.6675)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          45 à 50
        </text>
      </G>
      <G onClick={onClick} id="lot31">
        <rect
          x="576.298"
          y="146.736"
          fill="#FFFFFF"
          stroke="#000000"
          strokeMiterlimit="10"
          width="74.239"
          height="42.391"
        />
        <text
          transform="matrix(1 0 0 1 608.8461 172.0576)"
          fontFamily="'Montserrat'"
          fontSize="12px"
        >
          31
        </text>
      </G>
    </svg>
  );
};
