import styled from "styled-components";
import { BREAKPOINTS } from "../breakpoints";
import { PageContent, PageTitle, PageTitle2 } from "../components/Page";
import { CampingRoute } from "../router/CampingRoute";
import LogoFQCC from "./Home/fqcc.png";

const ColumnFlex = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    flex-direction: column-reverse;
  }
`;

const ColumnFlexGrow = styled.div`
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: auto;
    max-width: 50%;
    padding-right: 25px;
  }
`;

const RightColumnFlexGrow = styled(ColumnFlexGrow)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    justify-content: flex-start;
    align-items: flex-end;
  }
`;

const PricesDetails = styled.dl`
  dt {
    padding-bottom: 5px;
  }

  dd {
    margin-inline-start: 20px;
    line-height: 25px;
  }
`;

function Prices() {
  return (
    <PageContent>
      <PageTitle>Tarifs</PageTitle>
      <ColumnFlex>
        <ColumnFlexGrow>
          <PageTitle2>Tente au sol</PageTitle2>
          <p>
            <em>Ces prix incluent 2 adultes et 2 enfants</em>
          </p>
          <PricesDetails>
            <dt>2 services (eau, électricité)</dt>
            <dd>À la journée : 30,00$ + taxes</dd>
            <dd>À la semaine : 180,00$ + taxes</dd>
          </PricesDetails>

          <PageTitle2>Roulotte ou Tente-roulotte</PageTitle2>
          <p>
            <em>Ces prix incluent 2 adultes et 2 enfants</em>
          </p>
          <PricesDetails>
            <dt>3 services (eau, électricité 30 amp., égout)</dt>
            <dd>À la journée : 38,00$ + taxes</dd>
            <dd>À la semaine : 228,00$ + taxes</dd>
          </PricesDetails>

          <PageTitle2>Visiteurs</PageTitle2>
          <p>
            Adulte : 5,00$ * /Jour + 3,00$ * / Nuit
            <br />
            Enfant (moins de 18 ans) : 3,00$ * /Jour + 3,00$ * / Nuit
          </p>

          <p>
            <em>Une carte de visiteur pour la saison est aussi disponible</em>
            <br />
            Par personne : 25,00$ *<br />
            Par famille : 60,00$ *
          </p>

          <PageTitle2>Locations</PageTitle2>
          <p>
            <em>Ceintures incluses</em>
          </p>
          <p>
            <strong>Pour 2 heures</strong>
            <br />
            Kayak : 15$ * (2 de disponible)
            <br />
            Chaloupe : 15$ * (2 de disponible)
            <br />
            Pédalo 4 places : 15$ * (2 de disponible)
          </p>
        </ColumnFlexGrow>
        <RightColumnFlexGrow>
          <p>
            <strong>10% de rabais pour les membres</strong>
          </p>
          <a href="http://www.fqcc.ca/">
            <img alt="Logo FQCC" src={LogoFQCC} title="FQCC" />
          </a>
        </RightColumnFlexGrow>
      </ColumnFlex>
    </PageContent>
  );
}

export const PricesRoute: CampingRoute = {
  title: "Tarifs",
  path: "/tarifs",
  element: <Prices />,
};
