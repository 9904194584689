import { Coordinates } from "../components/Coordinates";
import { PageContent, PageTitle } from "../components/Page";
import { CampingRoute } from "../router/CampingRoute";

function WhereAreWe() {
  return (
    <PageContent>
      <PageTitle>Où sommes-nous?</PageTitle>
      <Coordinates />
      <iframe
        title="Camping des îles 2008"
        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=fr&amp;geocode=&amp;q=Camping+des+%C3%AEles+2008,+685,+Route+Des+%C3%8Eles,+St-Hilaire-de-Dorset(Qu%C3%A9bec),+QC,+Canada&amp;aq=0&amp;oq=campi&amp;sll=45.801999,-70.710754&amp;sspn=0.799403,1.783905&amp;ie=UTF8&amp;hq=Camping+des+%C3%AEles+2008,+685,+Route+Des+%C3%8Eles,+St-Hilaire-de-Dorset(Qu%C3%A9bec),+QC,+Canada&amp;hnear=&amp;radius=15000&amp;t=m&amp;ll=46.233053,-72.004395&amp;spn=2.279874,4.938354&amp;z=8&amp;iwloc=A&amp;output=embed"
        width="100%"
        height="600"
        frameBorder={0}
        marginWidth={0}
        marginHeight={0}
        scrolling="no"
      />
    </PageContent>
  );
}

export const WhereAreWeRoute: CampingRoute = {
  title: "Où sommes-nous?",
  path: "/ou-sommes-nous",
  element: <WhereAreWe />,
};
