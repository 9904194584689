import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const EventDateContainer = styled.div`
  position: relative;
`;

const EventDateTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  padding-top: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

interface EventDateProps {
  date: Date;
}

const dateMonthFormatter = new Intl.DateTimeFormat("fr-ca", {
  day: "numeric",
  month: "short",
});

export function EventDate({ date }: EventDateProps) {
  const day = dateMonthFormatter
    .formatToParts(date)
    .find((part) => part.type === "day");

  const month = dateMonthFormatter
    .formatToParts(date)
    .find((part) => part.type === "month");

  return (
    <EventDateContainer>
      <FontAwesomeIcon
        color="rgba(1, 139, 22, 0.8)"
        icon={faCalendar}
        size="4x"
      />
      <EventDateTextContainer>
        {day?.value}
        <br />
        {month?.value}
      </EventDateTextContainer>
    </EventDateContainer>
  );
}
