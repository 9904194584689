import { Link } from "react-router-dom";
import styled from "styled-components";
import { CampingRoute } from "../../router/CampingRoute";
import { Map } from "../../components/Map";
import ReactModal from "react-modal";
import { useState } from "react";
import { LotPeople, LotState } from "../../components/Map/people";
import { Burger } from "../../header/Burger";
import { PageContent, PageTitle } from "../../components/Page";

const PrintLink = styled.div`
  text-align: right;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`;

const Title = styled.h1`
  font-size: 18px;
  flex-grow: 1;
  margin: 0;
  padding-right: 20px;
`;

const ModalContent = styled.div`
  border-top: 1px solid #aaa;
  padding-top: 15px;
  padding-right: 25px;

  tr > td:first-child {
    width: 150px;
  }
`;

function toStateDisponibility(state: LotState) {
  switch (state) {
    case LotState.SeasonFree:
    case LotState.PassengerFree:
    case LotState.PassengerTent:
      return "Libre";
    case LotState.SeasonRented:
      return "Occupé";
    case LotState.Owner:
      return "Au propriétaires";
  }
}

function toStateType(state: LotState) {
  switch (state) {
    case LotState.SeasonFree:
    case LotState.SeasonRented:
      return "Saisonniers";
    case LotState.PassengerFree:
      return "Passagers";
    case LotState.PassengerTent:
      return "Passagers en tente";
    case LotState.Owner:
      return "Au propriétaires";
  }
}

function MapPage() {
  const [selectedLot, setSelectedLot] = useState<LotPeople>();

  return (
    <PageContent>
      <PageTitle>Plan</PageTitle>

      <Map onClick={setSelectedLot} />
      <PrintLink>
        <Link to="/map/print" target="_blank">
          Version imprimable
        </Link>
      </PrintLink>
      {selectedLot && (
        <ReactModal
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: 10,
              overflow: "hidden",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
          isOpen={Boolean(selectedLot)}
          contentLabel="Minimal Modal Example"
          ariaHideApp
        >
          <ModalHeader>
            <Title>Informations sur le terrain {selectedLot.name}</Title>
            <Burger isOpen={true} onClick={() => setSelectedLot(undefined)} />
          </ModalHeader>
          <ModalContent>
            <table>
              <tbody>
                {selectedLot.people && (
                  <tr>
                    <td>Campeur</td>
                    <td>{selectedLot.people.join(", ")}</td>
                  </tr>
                )}
                <tr>
                  <td>Disponibilité</td>
                  <td>{toStateDisponibility(selectedLot.state)}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{toStateType(selectedLot.state)}</td>
                </tr>
              </tbody>
            </table>
          </ModalContent>
        </ReactModal>
      )}
    </PageContent>
  );
}

export const MapRoute: CampingRoute = {
  title: "Plan",
  path: "/plan",
  element: <MapPage />,
};
