import { PropsWithChildren } from "react";
import "styled-components";
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from "styled-components";
import {
  BrowserMode,
  BrowserModeProvider,
  useBrowserMode,
} from "./BrowserModeProvider";

declare module "styled-components" {
  export interface DefaultTheme {
    borderRadius: string;
    colors: {
      background: string;
      main: string;
      secondary: string;
      text: string;
    };
  }
}

export const commonTheme = {
  borderRadius: "5px",
};

export const lightTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: "white",
    main: "cyan",
    secondary: "magenta",
    text: "black",
  },
};

export const darkTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: "black",
    main: "cyan",
    secondary: "magenta",
    text: "white",
  },
};

const RootBody = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.colors.background}
  }
`;

export interface LockableModeProps {
  lock?: BrowserMode;
}

function InnerBrowserModeProvider({
  children,
  lock,
}: PropsWithChildren<LockableModeProps>) {
  const browserMode = useBrowserMode();
  const userMode = lock || browserMode;

  return (
    <ThemeProvider
      theme={userMode === BrowserMode.Light ? lightTheme : darkTheme}
    >
      <RootBody />
      {children}
    </ThemeProvider>
  );
}

export function CampingThemeProvider({
  children,
  lock,
}: PropsWithChildren<LockableModeProps>) {
  return (
    <BrowserModeProvider>
      <InnerBrowserModeProvider lock={lock}>
        {children}
      </InnerBrowserModeProvider>
    </BrowserModeProvider>
  );
}
