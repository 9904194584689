import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {
  PageContent,
  PageTitle,
  PageTitle2,
  PageTitle3,
} from "../components/Page";
import { CampingRoute } from "../router/CampingRoute";

const HoursTable = styled.table`
  padding: 5px;

  td {
    line-height: 25px;
  }

  tr > td:first-child {
    width: 200px;
    font-size: 14px;
  }

  tr > td:last-child {
    font-size: 16px;
  }
`;

function Restaurant() {
  return (
    <PageContent>
      <PageTitle>Restaurant</PageTitle>
      <PageTitle2>
        <FontAwesomeIcon icon={faHome} /> Heures d'ouvertures
      </PageTitle2>
      <p>
        <small>
          <em>Ouvert lors des congés fériés & pour emporter seulement</em>
        </small>
      </p>
      <PageTitle3>17 mai au 18 juillet</PageTitle3>
      <HoursTable>
        <tbody>
          <tr>
            <td>Lundi au Mercredi</td>
            <td>Fermé</td>
          </tr>
          <tr>
            <td>Jeudi</td>
            <td>16h30 à 20h00</td>
          </tr>
          <tr>
            <td>Vendredi</td>
            <td>16h30 à 21h00</td>
          </tr>
          <tr>
            <td>Samedi</td>
            <td>8h00 à 20h00</td>
          </tr>
          <tr>
            <td>Dimanche</td>
            <td>8h00 à 15h00</td>
          </tr>
        </tbody>
      </HoursTable>
      <PageTitle3>19 juillet au 11 août (ouvert tous les jours)</PageTitle3>
      <HoursTable>
        <tbody>
          <tr>
            <td>Samedi au Jeudi</td>
            <td>8h00 à 20h00</td>
          </tr>
          <tr>
            <td>Vendredi</td>
            <td>8h00 à 21h00</td>
          </tr>
        </tbody>
      </HoursTable>
      <PageTitle3>12 août au 29 septembre</PageTitle3>
      <HoursTable>
        <tbody>
          <tr>
            <td>Lundi au Mercredi</td>
            <td>Fermé</td>
          </tr>
          <tr>
            <td>Jeudi</td>
            <td>16h30 à 20h00</td>
          </tr>
          <tr>
            <td>Vendredi</td>
            <td>16h30 à 21h00</td>
          </tr>
          <tr>
            <td>Samedi</td>
            <td>8h00 à 20h00</td>
          </tr>
          <tr>
            <td>Dimanche</td>
            <td>8h00 à 15h00</td>
          </tr>
        </tbody>
      </HoursTable>
    </PageContent>
  );
}

export const RestaurantRoute: CampingRoute = {
  title: "Restaurant",
  path: "/restaurant",
  element: <Restaurant />,
};
