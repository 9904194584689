import styled from "styled-components";
import Forest from "./forest.png";

const Root = styled.div`
  display: block;
`;

const WithPadding = styled.div`
  background-color: white;
  padding-top: 70px;
`;

const Trees = styled.div`
  background-image: url(${Forest});
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 96px;
  width: 100%;
`;

const Text = styled.a`
  text-align: center;
  margin: 10px 0;
  color: white;
  display: block;
  text-decoration: none;
`;

export function Footer() {
  return (
    <Root>
      <WithPadding>
        <Trees></Trees>
      </WithPadding>

      <Text href="https://www.quebec.ca/tourisme-et-loisirs/hebergement-touristique-courte-duree">
        Numéro d'enregistrement 202764
      </Text>
    </Root>
  );
}
