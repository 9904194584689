import styled from "styled-components";

export const PageContent = styled.div`
  width: 100%;
  max-width: 1100px;
  padding: 10px;
  box-sizing: border-box;
`;

export const PageTitle = styled.h1`
  font-size: 20px;
`;

export const PageTitle2 = styled.h1`
  font-size: 16px;
`;

export const PageTitle3 = styled.h1`
  font-size: 14px;
  text-transform: uppercase;
`;
