import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

export enum BrowserMode {
  Light = "light",
  Dark = "dark",
}

const ModeContext = createContext<BrowserMode>(BrowserMode.Light);

export function useBrowserMode() {
  return useContext(ModeContext);
}

export function BrowserModeProvider({ children }: PropsWithChildren<{}>) {
  const matchMediaMode = window.matchMedia("(prefers-color-scheme: dark)");
  const [autoMode, setAutoMode] = useState(
    matchMediaMode ? BrowserMode.Dark : BrowserMode.Light
  );

  useEffect(() => {
    return matchMediaMode.addEventListener("change", function (event) {
      setAutoMode(event.matches ? BrowserMode.Dark : BrowserMode.Light);
    });
  }, [matchMediaMode]);

  return (
    <ModeContext.Provider value={autoMode}>{children}</ModeContext.Provider>
  );
}
