import styled from "styled-components";
import logo from "./logo.png";
import { CampingRoute } from "../router/CampingRoute";
import { Menu } from "./Menu";

const HeaderContainerBase = styled.div`
  display: block;
  height: calc(240px + 6vw);
  max-height: 300px;
  overflow: hidden;
`;

const HeaderContentContainer = styled(HeaderContainerBase)`
  position: relative;

  @media only screen and (max-width: 800px) {
    text-align: center;
  }
`;

const Logo = styled.img`
  padding: min(3vw, 40px);
  filter: drop-shadow(5px 5px 14px #000000);
`;

const HeaderRoot = styled.header`
  background-color: #0b306a;
`;

export interface HeaderProps {
  pages: CampingRoute[];
}

export function Header({ pages }: HeaderProps) {
  return (
    <HeaderRoot>
      <HeaderContentContainer>
        <Logo src={logo} alt="logo" />
        <Menu pages={pages} />
      </HeaderContentContainer>
    </HeaderRoot>
  );
}
