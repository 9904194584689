import styled from "styled-components";
import { Lots } from "./Lots";
import { LotPeople } from "./people";
import { ReactComponent as Background } from "./plan-background.svg";
import { ReactComponent as Drawings } from "./plan-drawings.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const MapLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface MapProps {
  onClick?: (lot: LotPeople) => void;
}

export function Map({ onClick }: MapProps) {
  return (
    <Container>
      <Background />

      <MapLayer>
        <Drawings />
      </MapLayer>
      <MapLayer>
        <Lots onClick={onClick} />
      </MapLayer>
    </Container>
  );
}
