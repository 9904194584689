import { PageContent, PageTitle } from "../../components/Page";
import { CampingRoute } from "../../router/CampingRoute";
import { PICTURES } from "./pictures";
import styled from "styled-components";
import { BREAKPOINTS } from "../../breakpoints";

const StyledAspectRatio = styled.div`
  width: 100%;
  padding-bottom: ${({ ratio }: { ratio: number }) => (1 / ratio) * 100}%;
  position: relative;
  background-color: black;
  box-shadow: 0px 0px 12px 0px rgba(135, 135, 135, 1);

  &:hover img {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: all 0.2s ease-in-out;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
`;

const FlexItem = styled.div`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    width: 45%;
  }

  @media (min-width: ${BREAKPOINTS.desktop}px) {
    width: 30%;
  }
`;

function Photos() {
  return (
    <PageContent>
      <PageTitle>Photos</PageTitle>
      <Flex>
        {PICTURES.map((picture) => (
          <FlexItem key={picture.thumbnail}>
            <StyledAspectRatio ratio={4 / 3}>
              <a href={picture.href} target="__blank">
                <Image src={picture.thumbnail} alt="Camping des iles 2008" />
              </a>
            </StyledAspectRatio>
          </FlexItem>
        ))}
      </Flex>
    </PageContent>
  );
}

export const PhotosRoute: CampingRoute = {
  title: "Photos",
  path: "/photos",
  element: <Photos />,
};
