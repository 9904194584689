import styled from "styled-components";
import { BREAKPOINTS } from "../../breakpoints";
import { CampingRoute } from "../../router/CampingRoute";
import LogoBeauce from "./beauce.png";
import LogoFQCC from "./fqcc.png";

const Root = styled.div`
  text-align: center;
  padding: 30px;

  @media (max-width: ${BREAKPOINTS.tablet}px) {
    padding: 15px;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10vw;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

function Home() {
  return (
    <Root>
      <Text>
        <p>
          Le camping des îles est un terrain situé aux abords du Lac des îles à
          St-Hilaire-de-Dorset en Beauce.
          <br />
          Nous bénéficions d'un très beau paysage en pleine nature, paisible et
          agréable.
        </p>
        <p>
          Pour les amateurs de pêche, la truite mouchetée est au rendez-vous.
        </p>
        <p>
          Ce site comprend 50 terrains, boisés ou non, ainsi
          <br />
          qu'un restaurant, une piscine et un terrain de jeux pour les enfants.
        </p>
        <p>
          Un bloc sanitaire et une buanderie sont aussi à votre disposition 24
          heures sur 24.
        </p>
        <p>
          Nous offrons également la location d'embarcations nautiques (canot,
          pédalo, chaloupe, kayak) pour vos
          <br />
          promenades sur le lac.
        </p>
        <p>
          Venez profiter de vos vacances et en découvrant notre région. Ça vaut
          le détour!
        </p>
        <p>
          <strong>Bienvenue à tous et bonnes vacances</strong>
        </p>
        <p>
          <em>Véronique et Jasmin</em>
        </p>
      </Text>
      <Logos>
        <a href="http://www.destinationbeauce.com/">
          <img
            alt="Logo Membre de tourisme Beauce-Sartigan"
            width="300"
            src={LogoBeauce}
            title="Membre de tourisme Beauce-Sartigan"
          />
        </a>

        <a href="http://www.fqcc.ca/">
          <img alt="Logo FQCC" src={LogoFQCC} title="FQCC" />
        </a>
      </Logos>
    </Root>
  );
}

export const HomeRoute: CampingRoute = {
  title: "Acceuil",
  path: "/",
  element: <Home />,
};
