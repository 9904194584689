import KarineLauzon from "./karine_lauzon.jpeg";
import Band from "./zigneux.jpeg";

interface Event {
  date: Date;
  title: string;
  description?: string;
  hours?: string;
  cost?: string;
  admission?: string;
  signer?: string;
  images?: string[];
  subitems?: SubEvent[];
}

interface SubEvent {
  title: string;
  hours: string;
  cost: string;
  admission: string;
  images?: string[];
}

export const EVENTS: Event[] = [
  {
    date: new Date("2024-06-08 12:00"),
    title: "Bingo",
    description: "Tentez votre chance...",
    hours: "à partir de 19h00",
    cost: `5$ à l'entrée (2 cartes incluses valident pour les 10 tours) et 1$
              par cartes supplémentaires (valident 2 tours)`,
    admission: "pour tous",
  },
  {
    date: new Date("2024-06-22 12:00"),
    title: "Saint-Jean",
    signer: "Les Zigneux",
    description: `Musique, feu de camp et des feux d'artifice seront au rendez-vous.`,
    hours: "à partir de 20h00",
    cost: "5$ par adultes et 3$ par enfants",
    admission: "pour tous",
    images: [Band],
  },
  {
    date: new Date("2024-08-03 12:00"),
    title: "Noël des campeurs",
    subitems: [
      {
        title: `Arrivé du Père Noël, distribution des cadeaux et bingo pour les enfants`,
        hours: "à partir de 14h00",
        cost: "gratuit",
        admission: "pour tous",
      },
      {
        title: "Échange de cadeaux drôles et insolites (environ 20$)",
        hours: "à 21h00",
        cost: "gratuit",
        admission: "pour adultes",
      },
    ],
  },
  {
    date: new Date("2024-08-31 12:00"),
    title: "Halloween des campeurs",
    subitems: [
      {
        title: "Cueillette de bonbons sur le camping",
        cost: "gratuit",
        hours: "à partir de 14h00",
        admission: "pour tous",
      },
      {
        title: "DJ et animation (prix de présence pour les costumés)",
        cost: "gratuit",
        hours: "à partir de 20h00",
        admission: "pour adultes",
      },
    ],
  },
  {
    date: new Date("2024-09-07 12:00"),
    title: "Méchoui",
    cost: "6-11 ans 8$, 12-15 ans 16$, 16 ans et plus 32$ *pourboire non-inclu",
    hours: "à partir de 18h00 (réservation obligatoire avant le 6 août)",
    admission: "pour tous",
    signer: "Karine Lauzon",
    images: [KarineLauzon],
  },
];
